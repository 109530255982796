export enum MnemonicStep {
    START,
    IMPORT,
    CREATE
  }

export enum MnemonicLength {
  TWELVE = 12,
  TWENTY_FOUR = 24
}
